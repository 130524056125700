/* point color */
/* yellow */
.bg-yellow-point {
  --tw-bg-opacity: 1;
  background-color: rgba(246, 203, 68, var(--tw-bg-opacity));
}

.bg-lightyellow-point {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 245, 216, var(--tw-bg-opacity));
}

.bg-yellow-secondPoint {
  --tw-bg-opacity: 0.8;
  background-color: rgba(255, 233, 164, var(--tw-bg-opacity));
}

.text-yellow-point {
  --tw-text-opacity: 1;
  color: rgba(246, 203, 68, var(--tw-text-opacity));
}

.border-yellow-point {
  --tw-border-opacity: 1;
  border-color: rgba(246, 203, 68, var(--tw-border-opacity));
}

.border-yellow-secondPoint {
  --tw-border-opacity: 1;
  border-color: rgba(255, 233, 164, var(--tw-border-opacity));
}

.hover\:text-yellow-point:hover {
  --tw-text-opacity: 1;
  color: rgba(246, 203, 68, var(--tw-text-opacity));
}

.hover\:bg-lightyellow-point:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 233, 164, var(--tw-bg-opacity));
}

.focus\:bg-lightyellow-point:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 233, 164, var(--tw-bg-opacity));
}

.hover\:border-yellow-point:hover {
  --tw-border-opacity: 1;
  border-color: rgba(246, 203, 68, var(--tw-border-opacity));
}

.group:hover .group-hover\:text-yellow-point {
  --tw-text-opacity: 1;
  color: rgba(246, 203, 68, var(--tw-text-opacity));
}

.textBgYellow {
  position: relative;
  text-decoration: none;
  display: inline-block;
  padding: 0 1px
}

.textBgYellow:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 50%;
  left: 0;
  top: 60%;
  background-color: rgba(255, 233, 164);
}

/* gray */
.bg-gray-point {
  --tw-bg-opacity: 1;
  background-color: rgba(77, 77, 79, var(--tw-bg-opacity));
}

.text-gray-point {
  --tw-text-opacity: 1;
  color: rgba(77, 77, 79, var(--tw-text-opacity));
}

.border-gray-point {
  --tw-border-opacity: 1;
  border-color: rgba(77, 77, 79, var(--tw-border-opacity));
}

.hover\:text-gray-point:hover {
  --tw-text-opacity: 1;
  color: rgba(77, 77, 79, var(--tw-text-opacity));
}

.focus\:text-gray-point:focus {
  --tw-text-opacity: 1;
  color: rgba(77, 77, 79, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-gray-point {
  --tw-text-opacity: 1;
  color: rgba(77, 77, 79, var(--tw-text-opacity));
}

.hover\:bg-gray-point:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(77, 77, 79, var(--tw-bg-opacity));
}

.focus\:bg-gray-point:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(77, 77, 79, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-gray-point {
  --tw-bg-opacity: 1;
  background-color: rgba(77, 77, 79, var(--tw-bg-opacity));
}

/* blue */
.bg-blue-point {
  --tw-bg-opacity: 1;
  background-color: rgba(9, 169, 243, var(--tw-bg-opacity));
}

/* red */
.bg-red-point {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 76, 96, var(--tw-bg-opacity));
}

.text-red-point {
  --tw-text-opacity: 1;
  color: rgba(255, 76, 96, var(--tw-text-opacity));
}

/* each step color */
.bg-first-point {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 233, 164, var(--tw-bg-opacity));
}

.bg-second-point {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 189, 74, var(--tw-bg-opacity));
}

.bg-third-point {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 87, 105, var(--tw-bg-opacity));
}

.bg-fourth-point {
  --tw-bg-opacity: 1;
  background-color: rgba(188, 2, 22, var(--tw-bg-opacity));
}

/* font-size */
.font15 {
  font-size: 15px;
}

.font26 {
  font-size: 26px;

}

.font28 {
  font-size: 28px;
}

@media print {

  html,
  body {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
  }
}

/* pagination */
.pagination {
  display: flex;
  justify-content: center;
}

ul {
  list-style: none;
  padding: 0;
}

ul.pagination li {
  display: inline-block;
  width: 30px;
  border: 1px solid #e2e2e2;
  display: flex;
  justify-content: center;
  font-size: 23px;
}

ul.pagination li a {
  text-decoration: none;
  color: #828282;
  font-size: 18px;
}

ul.pagination li.active a {
  color: white;
}

ul.pagination li.active {
  background-color: #4b5563;
}

ul.pagination li a:hover,
ul.pagination li a.active {
  color: #374151
}

/* spinner */
.loader {
  border-top-color: #FFE9A4;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* 메인화면 슬라이드 페이지네이션 버튼 */
.swiper-pagination-bullet {
  background-color: #4D4D4F !important;
}

.guideTitle {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.answerTitle {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* top 버튼 */
.topBtn {
  position: fixed;
  bottom: 70px;
  right: 8px;
  z-index: 20;
  width: 40px;
  height: 40px;
  background-color: #828282;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* date-picker */
.boxshadow {
  box-shadow: 5px 10px 10px 10px #4D4D4F;
}

.react-datepicker .react-datepicker__header {
  padding: 1rem 0;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--selected {
  background-color: #F6CB44 !important;
  border-radius: 50% !important;
}

.react-datepicker__day--today {
  background-color: #dedede !important;
  border-radius: 50% !important;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 2.2rem !important;
  line-height: 2.2rem !important;
  margin: 0.22rem !important;
  font-size: 18px;
  color: rgba(55, 65, 81);
}

.datepickerHeader {
  display: flex;
  margin: 16px;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.react-datepicker__current-month {
  font-size: 20px !important;
}

.react-datepicker__day-name {
  font-size: 16px;
  color: #828282 !important;
}

.react-datepicker__input-container {
  max-width: 100px;
}

.react-datepicker__input-container>input {
  display: inine-block;
  font-size: 14px;
}

.hiddenBtn {
  color: #EDEFF4;
}